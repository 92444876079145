import React, { useState, useContext, useEffect } from 'react';
import { Card, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import defaultImage from '../../imgs/default.jpg';
import { PujaImgGalleyModel } from '../../components/Shared/pujaImgGalleryModal';
import upimg from '../../imgs/default.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';

const ReportsAddEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: '',
    slug:'',
    description: '',
    imageEn: '',
    price: '',
    priceUsd:''
  });

  const [inputFieldsHi, setInputFieldsHi] = useState({
    titleHi: '',
    descriptionHi: '',
    imageHi: '',
  });

  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [errors, setErrors] = useState({});
  const [errorsHi, setErrorsHi] = useState({});

  // Image gallery
  const [modalFieldKey, setModalFieldKey] = useState(null);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel, setClearSel] = useState(false);
  const [rindex, setRindex] = useState();
  const [imageEn, setImageEn] = useState('');
  const [imageHi, setImageHi] = useState('');

  const handleOpenModal = (fieldKey, index) => {
    setClearSel(true);
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };

  const extractPaths = url => {
    const extractPath = singleUrl => {
      const parts = singleUrl.split('cloudfront.net/');
      if (parts.length > 1) {
        return parts[1];
      }
      return singleUrl;
    };

    if (Array.isArray(url)) {
      return url.map(singleUrl => extractPath(singleUrl));
    } else if (typeof url === 'string') {
      return extractPath(url);
    }
    return url;
  };

  const handleSetImageUrl = (urls, fieldKey) => {
    let extractedPath = extractPaths(urls);
    setClearSel(false);
    if (fieldKey === 'ManageReportImageEn') {
      setInputFields(prev => ({ ...prev, imageEn: urls }));
      setImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'ManageReportImageHi') {
      setInputFieldsHi(prev => ({ ...prev, imageHi: urls }));
      setImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setInputFields(prev => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleChangeHi = e => {
    const { name, value } = e.target;

    setInputFieldsHi(prev => ({ ...prev, [name]: value }));

    if (!!errorsHi[name]) {
      setErrorsHi(prev => ({ ...prev, [name]: null }));
    }
  };

  // inputFieldSecEn

  const [inputFieldSecEn, setInputFieldSecEn] = useState([{ title: '', description: '' }]);

  const addInputFieldEn = () => {
    setInputFieldSecEn([...inputFieldSecEn, { title: '', description: '' }]);
  };

  const removeInputField = index => {
    const values = [...inputFieldSecEn];
    values.splice(index, 1);
    setInputFieldSecEn(values);
  };

  const handleChangeSec = (index, evnt) => {
    const { name, value } = evnt.target;

    let key;
    key = evnt?.target?.name;
    if (!!errors?.fieldSelectionEn) {
      errors?.fieldSelectionEn?.forEach((field, index) => {
        if (errors.fieldSelectionEn[index][key] != null || errors.fieldSelectionEn[index][key] != '') {
          errors.fieldSelectionEn[index][key] = null;
        }
      });
    }
    const list = [...inputFieldSecEn];
    list[index][name] = value;
    setInputFieldSecEn(list);
  };

  // inputFieldSecHi

  const [inputFieldSecHi, setInputFieldSecHi] = useState([{ title: '', description: '' }]);

  const addInputFieldHi = () => {
    setInputFieldSecHi([...inputFieldSecHi, { title: '', description: '' }]);
  };

  const removeInputFieldHi = index => {
    const values = [...inputFieldSecHi];
    values.splice(index, 1);
    setInputFieldSecHi(values);
  };

  const handleChangeSecHi = (index, evnt) => {
    const { name, value } = evnt.target;

    let key;
    key = evnt?.target?.name;
    if (!!errorsHi?.fieldSelectionHi) {
      errorsHi?.fieldSelectionHi?.forEach((field, index) => {
        if (errorsHi.fieldSelectionHi[index][key] != null || errorsHi.fieldSelectionHi[index][key] != '') {
          errorsHi.fieldSelectionHi[index][key] = null;
        }
      });
    }
    const list = [...inputFieldSecHi];
    list[index][name] = value;
    setInputFieldSecHi(list);
  };

  const validateInputs = () => {
    const newErrors = {};
    let isLocalCantentValid = false;

    const inputFieldsErrorsSecEn = [];
    inputFieldSecEn?.forEach((field, index) => {
      let errorMsgs = {
        title: '',
        description: '',
      };

      Object.keys(field).forEach(key => {
        if (field[key] === '' || field[key] === 'undefined') {
          isLocalCantentValid = true;
          let errMsg = `Please add  ${key === 'title' ? 'title' : key === 'description' ? 'description' : key}.`;
          errorMsgs[key] = errMsg;
        }
      });
      inputFieldsErrorsSecEn.push(errorMsgs);
    });

    if (isLocalCantentValid && inputFieldsErrorsSecEn.length > 0) newErrors.fieldSelectionEn = inputFieldsErrorsSecEn;

    if (!inputFields.title || inputFields.title.trim() === '') {
      newErrors.title = 'Please enter Title.';
    }
    if (!inputFields.description || inputFields.description.trim() === '') {
      newErrors.description = 'Please enter Description.';
    }

    if (!inputFields.price || inputFields.price.trim() === '') {
      newErrors.price = 'Please enter INR Price.';
    }

    if (!inputFields.slug || inputFields.slug.trim() === '') {
      newErrors.slug = 'Please enter Slug.';
    }

    if (!inputFields.priceUsd || inputFields.priceUsd.trim() === '') {
      newErrors.priceUsd = 'Please enter USD Price.';
    }

    if (!inputFields.imageEn) {
      newErrors.imageEn = 'Please add an Image.';
    }

    return newErrors;
  };

  const validateInputsHi = () => {
    const newErrorsHi = {};
    let isLocalCantentValidHi = false;
    const inputFieldsErrorsSecHi = [];

    inputFieldSecHi?.forEach((field, index) => {
      let errorMsgs = {
        title: '',
        description: '',
      };

      Object.keys(field).forEach(key => {
        if (field[key] === '' || field[key] === 'undefined') {
          isLocalCantentValidHi = true;
          let errMsg = `Please add  ${key === 'title' ? 'title' : key === 'description' ? 'description' : key}.`;
          errorMsgs[key] = errMsg;
        }
      });
      inputFieldsErrorsSecHi.push(errorMsgs);
    });

    if (isLocalCantentValidHi && inputFieldsErrorsSecHi.length > 0)
      newErrorsHi.fieldSelectionHi = inputFieldsErrorsSecHi;

    if (!inputFieldsHi.titleHi || inputFieldsHi.titleHi.trim() === '') {
      newErrorsHi.titleHi = 'Please enter Title.';
    }

    if (!inputFieldsHi.descriptionHi || inputFieldsHi.descriptionHi.trim() === '') {
      newErrorsHi.descriptionHi = 'Please enter Description.';
    }

    if (!inputFieldsHi.imageHi) {
      newErrorsHi.imageHi = 'Please add an Image.';
    }

    return newErrorsHi;
  };

  const handleReset = () => {
    setInputFields({
      title: '',
      slug:'',
      description: '',
      imageEn: '',
      price: '',
      priceUsd:''
    });

    setInputFieldSecEn([{ title: '', description: '' }]);
    setInputFieldSecHi([{ title: '', description: '' }]);

    setInputFieldsHi({
      titleHi: '',
      descriptionHi: '',
      imageHi: '',
    });
    setErrors({});
    setErrorsHi({});
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi);
      setLoading(false);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('title', inputFields.title);
    formData.append('description', inputFields.description);
    formData.append('price', inputFields.price);
    formData.append('slug', inputFields.slug);
    formData.append('priceUsd', inputFields.priceUsd);
    formData.append('image', imageEn);
    formData.append('inputFieldSecEn', JSON.stringify(inputFieldSecEn));

    formData.append('titleHi', inputFieldsHi.titleHi);
    formData.append('descriptionHi', inputFieldsHi.descriptionHi);
    formData.append('imageHi', imageHi);
    formData.append('inputFieldSecHi', JSON.stringify(inputFieldSecHi));

    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      const response = await APIServices.post('chadhawaSamgri/add', formData, headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        handleReset();
        // alert("successfull")
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false); // Reset loading state after the request is completed
    }
  };

  useEffect(() => {
    if (editDataId) {
      showEditData();
    }
  }, []);

  const showEditData = item => {
    setSelectedId(item?._id);
    setInputFields({
      title: item?.title,
      description: item?.description,
      price: item?.price,
      slug: item?.slug,
      priceUsd: item?.priceUsd,
      imageEn: item?.image,
    });

    setInputFieldsHi({
      titleHi: item?.titleHi,
      descriptionHi: item?.descriptionHi,
      imageHi: item?.imageHi,
    });

    setInputFieldSecEn(item?.inputFieldSecEn ?? [{ title: '', description: '' }]);

    setInputFieldSecHi(item?.inputFieldSecHi ?? [{ title: '', description: '' }]);
  };

  useEffect(() => {
    getEditData();
  }, [editDataId]);

  const getEditData = async () => {
    if (editDataId) {
      let res = await APIServices.get(`chadhawaSamgri/getDetailById/${editDataId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        if (apiData?.status) {
          showEditData(apiData?.data[0]);
        }
      }
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', inputFields?.title);
    formData.append('description', inputFields?.description);
    formData.append('price', inputFields?.price);
    formData.append('slug', inputFields?.slug);
    formData.append('priceUsd', inputFields?.priceUsd);
    if (imageEn !== '') {
      formData.append('image', imageEn);
    }

    formData.append('inputFieldSecEn', JSON.stringify(inputFieldSecEn));

    formData.append('titleHi', inputFieldsHi?.titleHi);
    formData.append('descriptionHi', inputFieldsHi?.descriptionHi);
    if (imageHi !== '') {
      formData.append('imageHi', imageHi);
    }
    formData.append('inputFieldSecHi', JSON.stringify(inputFieldSecHi));

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    setLoading(true);
    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi);
    } else {
      const response = await APIServices.put(`chadhawaSamgri/editOne/${editDataId}`, formData);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        navigate('/chadhawaList');
      } else {
        toast.error(response?.data?.message);
      }
    }
  };
  return (
    <div className='container mt-5'>
      {/* <button className='as_btn mb-3' onClick={()=>{ navigate(-1)}}>
        Go Back
      </button> */}
      <Form
        className='add_blog_form box-shadow p-20 br-6'
        onSubmit={e => `${selectedId ? handleUpdate(e) : handleSubmit(e)}`}
      >
        <Row>
          <Col sm={6}>
            <h5 className='text-center mb-4'>English Content</h5>
            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitle'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  isInvalid={!!errors.title}
                  value={inputFields.title}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicSlug'>
              <Form.Label className='required' column sm='3'>
                Slug
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  isInvalid={!!errors.slug}
                  value={inputFields.slug}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.slug}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitle'>
              <Form.Label className='required' column sm='3'>
                Description :
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 100 }}
                  placeholder='Description...'
                  name='description'
                  isInvalid={!!errors.description}
                  value={inputFields.description}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formImageInput'>
              <Form.Label className='required' column sm='3'>
                Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  id='upload-photo-1'
                  name='imageEn'
                  className='d-none'
                  isInvalid={!!errors?.imageEn}
                  onClick={e => {
                    handleOpenModal('ManageReportImageEn');
                    handleChange(e);
                  }}
                />

                <img
                  name='imageEn'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFields?.imageEn ? inputFields?.imageEn : upimg}
                  onClick={e => {
                    handleOpenModal('ManageReportImageEn');
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.imageEn}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitle'>
              <Form.Label className='required' column sm='3'>
                Price
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Price...'
                  name='price'
                  isInvalid={!!errors.price}
                  value={inputFields.price}
                  onKeyPress={evnt => {
                    // Allow only numeric characters, backspace, and the decimal point
                    if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                      evnt.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.price}</Form.Control.Feedback>
              </Col>
            </Form.Group> */}

            <div className=''>
              <h5 className='mt-4 mb-3'> Benefits : </h5>
              <div
                onClick={addInputFieldEn}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  cursor: 'pointer',
                  color: 'green',
                }}
              >
                <div className='Et-btn add-btn1 btn-success1'>
                  Add
                  <FaPlus className='hd-cr' title='Add Section' />
                </div>
              </div>
            </div>

            {inputFieldSecEn.map((field, index) => {
              const { title, description } = field;
              return (
                <>
                  <div key={index} className=''>
                    <Row>
                      <Col sm={12} className='mt-3'>
                        <Form.Group as={Row} controlId={`formTitle-${index}`}>
                          <Form.Label column sm='3'>
                            Title
                          </Form.Label>
                          <Col sm='9'>
                            <Form.Control
                              type='text'
                              name='title'
                              placeholder='title...'
                              value={title}
                              onChange={evnt => handleChangeSec(index, evnt)}
                              isInvalid={!!errors?.fieldSelectionEn?.[index]?.title}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors?.fieldSelectionEn?.[index]?.title}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={12} className='mt-3'>
                        <Form.Group as={Row} controlId={`formGlobalDescription-${index}`}>
                          <Form.Label column sm='3'>
                            Description
                          </Form.Label>
                          <Col sm='9'>
                            <Form.Control
                              type='text'
                              as='textarea'
                              style={{ height: 100 }}
                              placeholder='Description ...'
                              name='description'
                              value={description}
                              onChange={evnt => handleChangeSec(index, evnt)}
                              isInvalid={!!errors?.fieldSelectionEn?.[index]?.description}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors?.fieldSelectionEn?.[index]?.description}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {inputFieldSecEn.length !== 1 ? (
                        <div
                          onClick={() => removeInputField(index)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                        >
                          <button className='Dt-btn mt-2 add-btn1'>
                            Remove
                            <FaMinus className='hd-cr fw-bold' title='Remove Section' />
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Row>
                  </div>
                </>
              );
            })}
          </Col>

          <Col sm={6}>
            <h5 className='text-center mb-4'>Hindi Content</h5>
            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitleHi'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='titleHi'
                  isInvalid={!!errorsHi.titleHi}
                  value={inputFieldsHi.titleHi}
                  onChange={handleChangeHi}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi.titleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicSlug'>
              <Form.Label className='required' column sm='3'>
                Slug
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  isInvalid={!!errors.slug}
                  value={inputFields.slug}
                  readOnly
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.slug}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicDescriptionHi'>
              <Form.Label className='required' column sm='3'>
                Description
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 100 }}
                  placeholder='Description...'
                  name='descriptionHi'
                  isInvalid={!!errorsHi.descriptionHi}
                  value={inputFieldsHi.descriptionHi}
                  onChange={handleChangeHi}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi.descriptionHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3 mx-2' controlId='formImageInputHi'>
              <Form.Label className='required' column sm='3'>
                {' '}
                Image{' '}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  id='upload-photo-1'
                  name='imageHi'
                  className='d-none'
                  isInvalid={!!errorsHi?.imageHi}
                  onClick={e => {
                    handleOpenModal('ManageReportImageHi');
                    handleChangeHi(e);
                  }}
                />

                <img
                  name='imageHi'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFieldsHi?.imageHi ? inputFieldsHi?.imageHi : upimg}
                  onClick={e => {
                    handleOpenModal('ManageReportImageHi');
                    handleChangeHi(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi?.imageHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <div style={{ marginTop: '40px' }}>
              <h5 className='mt-4'> Benefits : </h5>
              <div
                onClick={addInputFieldHi}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  cursor: 'pointer',
                  color: 'green',
                }}
              >
                <div className='Et-btn add-btn1 btn-success1'>
                  Add
                  <FaPlus className='hd-cr' title='Add Section' />
                </div>
              </div>
            </div>

            {inputFieldSecHi.map((field, index) => {
              const { title, description } = field;
              return (
                <>
                  <div key={index} className='mt-3'>
                    <Row>
                      <Col sm={12}>
                        <Form.Group as={Row} controlId={`formTitleHi-${index}`}>
                          <Form.Label column sm='3'>
                            Title
                          </Form.Label>
                          <Col sm='9'>
                            <Form.Control
                              type='text'
                              name='title'
                              placeholder='title...'
                              value={title}
                              onChange={evnt => handleChangeSecHi(index, evnt)}
                              isInvalid={!!errorsHi?.fieldSelectionHi?.[index]?.title}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errorsHi?.fieldSelectionHi?.[index]?.title}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={12} className='mt-3'>
                        <Form.Group as={Row} controlId={`formDescriptionHi-${index}`}>
                          <Form.Label column sm='3'>
                            Description
                          </Form.Label>
                          <Col sm='9'>
                            <Form.Control
                              type='text'
                              as='textarea'
                              style={{ height: 100 }}
                              placeholder='Description ...'
                              name='description'
                              value={description}
                              onChange={evnt => handleChangeSecHi(index, evnt)}
                              isInvalid={!!errorsHi?.fieldSelectionHi?.[index]?.description}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errorsHi?.fieldSelectionHi?.[index]?.description}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {inputFieldSecHi.length !== 1 ? (
                        <div
                          onClick={() => removeInputFieldHi(index)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                        >
                          <button className='Dt-btn mt-2 add-btn1'>
                            Remove
                            <FaMinus className='hd-cr fw-bold' title='Remove Section' />
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Row>
                  </div>
                </>
              );
            })}

          </Col>
          <Row>
            <Col md={6}>
              <Form.Group as={Row} className='mb-3 my-5' controlId='formBasicTitle'>
                <Form.Label className='required' column sm='3'>
                  Price INR
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    type='text'
                    placeholder='Price INR...'
                    name='price'
                    isInvalid={!!errors?.price}
                    value={inputFields?.price}
                    onKeyPress={evnt => {
                      if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                        evnt.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.price}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row} className='mb-3 my-5' controlId='formBasicTitle'>
                <Form.Label className='required' column sm='3'>
                  Price USD
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    type='text'
                    placeholder='Price USD...'
                    name='priceUsd'
                    isInvalid={!!errors?.priceUsd}
                    value={inputFields?.priceUsd}
                    onKeyPress={evnt => {
                      // Allow only numeric characters, backspace, and the decimal point
                      if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                        evnt.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.priceUsd}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Row>

        <div className='text-center'>
          <Button variant='primary' type='submit' className='as_btn'>
            {loading ? 'Loading...' : `${selectedId ? 'Edit' : 'Add'}`}
          </Button>
          <Button variant='primary' type='button' className='as_btn' onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button variant='primary' type='button' className='as_btn' onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Form>
      <PujaImgGalleyModel
        id='ImgGalleyModel'
        show={showImageGalleyModel}
        onHide={() => setShowImageGalleyModel(false)}
        modalHeading={'ImgGalley'}
        size='lg'
        handleSetImageUrl={handleSetImageUrl}
        fieldKey={modalFieldKey}
        setClearSel={setClearSel}
        clearSel={clearSel}
      />
      <ToastContainer />
    </div>
  );
};

export default ReportsAddEdit;
